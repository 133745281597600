import React from 'react';
import { Link } from 'gatsby';
import HtmlParser from 'react-html-parser';
import GETLINK from "../getlink";
import { TwitterBlack, FacebookBlack, InstagramBlack, LinkedinBlack, YoutubeBlack, Tictok} from '../icon/icon';
import PRSLogo from "../../images/prs.png";
import ARLAPMLogo from "../../images/arla-property-mark.png";
import NAEAPMLogo from "../../images/property-mark.png";

const FooterBottom = ({bottomContent}) => {
  return (
    <div className="footer-bottom">    
         <div className="footer-bottom-left">
         <div className="socials">
              <ul>
                <li><a href={bottomContent?.Twitter_Link} target="_blank"  rel="noreferrer"><span>Twitter</span> <TwitterBlack /></a></li>
                <li><a href={bottomContent?.Facebook_Link} target="_blank"  rel="noreferrer"><span>Facebook</span> <FacebookBlack /></a></li>
                <li><a href={bottomContent?.Instagram_Link} target="_blank"  rel="noreferrer"><span>Instagram</span> <InstagramBlack /></a></li>
                <li><a href={bottomContent?.Linkedin_Link} target="_blank"  rel="noreferrer"><span>Linkedin</span> <LinkedinBlack /></a></li>
                <li><a href={bottomContent?.Youtube_Link} target="_blank"  rel="noreferrer"><span>YouTube</span> <YoutubeBlack /></a></li>
                <li className='tiktok'><a href={bottomContent?.Tiktok_Lnk} target="_blank"  rel="noreferrer"><span>Tiktok</span> <Tictok /></a></li> 
              </ul>
            </div>
          <div className="copy text-18">
            {bottomContent.Footer_Menu_Links && bottomContent.Footer_Menu_Links.length > 0 &&
                <ul>
                          {bottomContent.Footer_Menu_Links.map((node, key) => (
                              <li className='text-18' key={key}>
                                  {node.Menu_Link ?                                     
                                     <GETLINK label={node.Menu_Label} link={node.Menu_Link && node.Menu_Link.URL} Secondary_URL={node.Menu_Link && node.Menu_Link.Secondary_URL}/>
                                      :""
                                  }
                                  <span className="link-separator">|</span>
                              </li>
                          ))}
                </ul>
              }
            <p className="copy text-18">{HtmlParser(bottomContent?.Company_Reg_Details)}</p>
            <p className="copy text-18 spacing-bottom-32">© {new Date().getFullYear()} Anthony Pepe. All Rights Reserved.</p>
            <p className="site-by text-18 m-0">
              Site by <a href="https://starberry.tv/" target="_blank">Starberry</a>
            </p>
          </div>
          </div>
          <div className="footer-bottom-right">
          {bottomContent?.Footer_Client_Logos?.length > 0 && (
              <ul>
                {bottomContent.Footer_Client_Logos.map((c) => {
                  const logo_url = c.Client_Image?.internal?.description
                    ? c.Client_Image.internal.description.replace(/File |"/g, '')
                    : '';

                  return c.Client_Url ? (
                    <li key={c.id}>
                      <a href={c.Client_Url} target="_blank" rel="noopener noreferrer">
                        <img
                          loading="lazy"
                          src={logo_url}
                          alt={c.Client_Label || 'Client Logo'}
                        />
                      </a>
                    </li>
                  ) : (
                    <li key={c.id}>
                      <img
                        loading="lazy"
                        src={logo_url}
                        alt={c.Client_Label || 'Client Logo'}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
    </div>
  );
};

export default FooterBottom;